import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from '../../locales';


const i18n = i18next.createInstance();

/**
 * @link https://react.i18next.com/
 */
i18n
  .use( initReactI18next )
  .init( {
    ns           : [ 'common', 'about' ],
    defaultNS    : 'common',
    resources,
    fallbackLng  : 'en',
    debug        : false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator : false,
    nsSeparator  : false,
    react        : {
      useSuspense: false,
    },
  } );

export default i18n;