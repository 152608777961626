import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';


export function wrapPageElement( { element, props } ) {
  if ( props.pageContext.language !== i18n.language ) {
    i18n.changeLanguage( props.pageContext.language );
  }

  return (
    <I18nextProvider i18n={ i18n }>
      { element }
    </I18nextProvider>
  );
}