module.exports = [{
      plugin: require('../plugins/gatsby-plugin-ryusei-light-remark/gatsby-browser.js'),
      options: {"plugins":[],"languageName":true,"copy":{"html":"<svg viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n <path d=\"m10.3 1.26c-1.11 0-2.09 0.593-2.64 1.47h-1.41c-1.77 0-3.23 1.46-3.23 3.23v13.5c0 1.77 1.46 3.23 3.23 3.23h11.5c1.77 0 3.23-1.46 3.23-3.23v-13.5c0-1.77-1.46-3.23-3.23-3.23h-1.41c-0.552-0.882-1.53-1.47-2.64-1.47zm0 2.24h3.44c0.501 0 0.869 0.37 0.869 0.871s-0.368 0.869-0.869 0.869h-3.44c-0.501 0-0.869-0.368-0.869-0.869s0.368-0.871 0.869-0.871zm-4.05 1.38h0.98c0.249 1.46 1.54 2.6 3.07 2.6h3.44c1.53 0 2.82-1.13 3.07-2.6h0.982c0.616 0 1.08 0.462 1.08 1.08v13.5c0 0.616-0.462 1.08-1.08 1.08h-11.5c-0.616 0-1.08-0.462-1.08-1.08v-13.5c0-0.616 0.462-1.08 1.08-1.08zm2.59 5.61a1 1 0 0 0-1 1 1 1 0 0 0 1 1h6.35a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm0 4.04a1 1 0 0 0-1 1 1 1 0 0 0 1 1h6.35a1 1 0 0 0 1-1 1 1 0 0 0-1-1z\" />\n</svg>\n","activeHtml":"<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\">\n <path fill=\"currentColor\" d=\"m33.4 5.54-17.6 17.6-9.09-9.09-5.66 5.66 14.8 14.8 5.66-5.66-0.0156-0.0156 17.6-17.6-5.66-5.66z\"/>\n</svg>\n"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>","offsetY":"50"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Splide","short_name":"Splide","description":"アクセシビリティに配慮した軽量・高機能スライダー。ほかのライブラリに依存せず、Lighthouseのエラーもありません。","start_url":"/","background_color":"#0c120f","theme_color":"#b4e900","display":"standalone","legacy":false,"icon":"src/assets/images/site/favicon-192x192.png","icons":[{"src":"src/assets/images/site/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/site/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1246b8c7f3ab2be5f7778ca9233466a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146338426-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
